@import url(../fonts/segoe-ui/stylesheet.css);

:root {
  --DefaultTextColor: #ffffff;
  --SecondaryTextColor: #000000;
  --TreviewBranchColor: #242B3D;
  --HeaderBackground: #00112E ;
  --BorderColor: #191a1d;

  --blue: #01D1FA;
  --vh: 100vh;
  --width: 100%;
  --height: 100%;
  --red: #DD1C24;
  --green: #498012;
  --font: Segoe UI;
  --sidePanel: #2E323D;
  --gutter: 20px;
  --baseFont: 16px;
  --header: 101px;
  --container: 1460px;
  --siderbarWidth: 380px;
  --panelPadding: 5px;
  --localFonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"

}

html {
  width: var(--width);
  height: var(--height);
}
body {
  background: #00112E;
  font-family: var(--font), var(--localFonts);
  font-size: var(--baseFont);
  height: var(--height);
  overflow: hidden;
  user-select: none;
}

/* Scrollbar css */
::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #454c8b;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  background: #495192;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:active {
  background: #4e58af;
  background-clip: padding-box;
}

::-webkit-scrollbar-track {
  background: #41424b;
  background-clip: padding-box;
}

::-webkit-scrollbar-corner {
  background: transparent;
}
/* Scrollbar css */

/* Base */
a {
  color: var(--blue);
}
.form-label {
  font-size: 0.875rem;
  line-height: 0.875rem;
  letter-spacing: 0.06px;
  color: #B7B7B7;
}
.form-control {
  font-size: 0.875rem;
  font-weight: 600;
  color: #B7B7B7;
  background-color: #31354B;
  border: 1px solid #31354B;
  letter-spacing: 0.06px;
}
.form-control:disabled,
.form-control.disabled {
  opacity: .5;
  user-select: none;
}
.form-control:disabled,
.form-control.disabled {
  color: #B7B7B7;
  background-color: #31354B;
  border: 1px solid #31354B;
}
.form-control:focus {
  border: 1px solid var(--blue);
  background: #34384f;
  color: #ffffff;
  outline: none;
  box-shadow: none;
}
.invalid-feedback {
  color: var(--red);
}
.btn {
  border-radius: 100px;
  letter-spacing: 0.06px;
}
.btn:disabled,
.btn.disabled {
  pointer-events: none;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn-primary {
  color: var(--DefaultTextColor);
  background-color: #353A68;
  border-color: #353A68;
}
.btn-primary:hover,
.btn-primary:focus {
  color: var(--DefaultTextColor);
  background-color: #353A68;
  border-color: #353A68;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: var(--DefaultTextColor);
  background-color: #353A68;
  border-color: #353A68;
}

.btn-light-outline {
  color: var(--DefaultTextColor);
  background-color: #252b39;
  border-color: var(--DefaultTextColor);
}
.btn-light-outline:hover,
.btn-light-outline:focus {
  color: #252b39;
  background-color: var(--DefaultTextColor);
  border-color: var(--DefaultTextColor);
}


.w-m-65 {
  min-width: 65px;
}
/* Base */


/* Header */
header.header {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  height: 80px;
  padding-top: 0px;
  background-color: var(--HeaderBackground);
  box-shadow: inset -0px 37px 37px -37px rgba(0, 0, 0, 0.993);
}

header.header.header-fixed-top {}

.header-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
}

.header-brand {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.header-title {
  font-size: 1.5rem;
  color: #FFFFFF;
  margin-left: 1.5rem;
}

.header-right {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0px;
  height: 60px;
  margin-top: 25px;
}

.nav-navbar {
  margin-right: 74px;
}

.nav-navbar .nav-link {
  font-size: 20px;
  color: #D7D7D7;
  transition: 0.2s ease all;
}

.nav-navbar .nav-link.navActive {
  color: #39a2f3;
}

.nav-navbar .nav-link:hover,
.nav-navbar .nav-link:focus {
  color: var(--DefaultTextColor);
  text-decoration: none;
}

.nav-navbar .nav-link .nav-link-text {
  color: inherit;
  text-decoration: inherit;
}

.nav-navbar .nav-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.nav-navbar .nav-menu>li {
  margin-right: 49px;
}

.nav-navbar .nav-menu>li>a {
  font: normal normal normal 20px/27px;
  letter-spacing: 0px;
  color: #D7D7D7;
  display: inline-block;
  transition: 0.2s ease all;
}

.nav-navbar .nav-menu>li>a:hover,
.nav-navbar .nav-menu>li>a:focus {
  color: #fff;
  text-decoration: none;
}

.notification-dropdown {
  position: relative;
}

.notification-bell {}

.io-notification {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1;
  background: #252B39;
  border-radius: 12px;
  min-width: 334px;
  max-width: 334px;
  max-height: 312px;
  overflow: auto;
  display: none;
}

.io-notification.open {
  display: block;
}

.io-notification-wrapper {
  padding: 25px 0 25px 22px;
}

.io-notification-header {
  font: normal normal 600 14px/19px Segoe UI;
  letter-spacing: 0.08px;
  color: #858585;
  margin-bottom: 10px;
}

.io-notification-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.io-notification-list>.io-notification-item {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #858585;
}

.io-notification-list>.io-notification-item:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0 solid #858585;
}

.io-notification-time {
  font: normal normal 600 10px/14px Segoe UI;
  letter-spacing: 0.06px;
  color: #B7B7B7;
  margin-bottom: 4px;
}

.io-notification-content {
  font: normal normal normal 12px/16px Segoe UI;
  letter-spacing: 0.07px;
  color: #858585;
}

.header-profile {
  margin-left: 74px;
}

.profile-dropdown {}

.profile-dropdown-components {
  display: flex;
  align-items: center;
  padding: 0;
  padding-left: 1em;
}

.profile-dropdown-components::after {
  content: none;
}

.profile-dropdown .userName {
  cursor: pointer;
  font-size: 16px;
  color: var(--DefaultTextColor);
  margin-bottom: 0;
}

.profile-dropdown .userName .navBarText {
  color: #898989;
  font-size: 16px;
}

.navBarText {
  font-size: 16px;
}

.profile-dropdown .userCaretIcon {
  margin-left: 10px;
}

.profile-dropdown .nav-dropdown-image {
  margin-left: 10px;
  width: 32px;
  height: 32px;
  object-fit: cover;
}

/* Header */

/* Breadcrumb */

.breadcrumb {
  background-color: transparent;
  margin-bottom: 0;
  border-radius: 0;
  padding: 0;
  margin-bottom: 0;
}

.breadcrumb-item>a {
  color: var(--DefaultTextColor);
  text-decoration: none;
}

.breadcrumb-item.active {
  color: var(--DefaultTextColor);
}

/* Modal css */
.custom-modal .modal-content {
  background-color: #252B39;
  border: 0px solid rgba(0,0,0,.2);
  border-radius: 12px;
}
.custom-modal .modal-content .modal-header {
  padding: 25px;
  border-bottom: 0;
}
.custom-modal .modal-content .modal-header .close {
  color: var(--DefaultTextColor);
  display: none;
}
.custom-modal .modal-content .modal-header .modal-title {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: var(--DefaultTextColor);
}
.custom-modal .modal-body {
  padding: 0px 25px;
}
.custom-modal .content-text {
  color: var(--DefaultTextColor);
}
.custom-modal .modal-footer {
  border-top: 0;
    padding: 25px;
}
.custom-modal .modal-footer .btn {
  min-width: 80px;
}
/* Modal css */

/* Panel */
.io-subpanel {
  background: #31354B;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 2.5rem;
  height: 30px;
  padding-left: 1rem;
  padding-right: 8px;
}

.io-subpanel-left {
  display: flex;
  align-items: center;
}

.io-subpanel-right {
  display: flex;
  align-items: center;
}

.io-subpanel-title {
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0.005rem;
  color: var(--DefaultTextColor);
  margin-right: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.io-info {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0;
}
.io-info:hover,
.io-info:focus {
  color: #fff;
}

.io-subpanel-actions {
  display: flex;
  align-items: center;
}
.io-subpanel-actions .btn {
  margin-left: 10px;
}

.io-btn:disabled {
  opacity: .5;
  pointer-events: none;
}

.io-btn {
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.05px;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #27293a;
}

.io-btn-light {
  font-size: 13px;
  line-height: 10px;
  letter-spacing: 0.05px;
  color: var(--DefaultTextColor);
  border: 1px solid #6b6b6b;
  border-radius: 100px;
  padding: 5px 8px;
  white-space: nowrap;
  background: #27293a !important;
}

.io-btn-light:hover,
.io-btn-light:focus {
  color: var(--DefaultTextColor);
  border: 1px solid var(--DefaultTextColor);
  background-color: #363847;
}

.io-btn-success {
  color: var(--DefaultTextColor);
  border: 1px solid var(--green);
  background-color: var(--green);
}
.io-btn-success:hover,
.io-btn-success:focus {
  color: var(--DefaultTextColor);
  border: 1px solid var(--green);
  background-color: var(--green);
}

.io-btn-danger {
  color: var(--DefaultTextColor);
  border: 1px solid var(--red);
  background-color: var(--red);
}
.io-btn-danger:hover,
.io-btn-danger:focus {
  color: var(--DefaultTextColor);
  border: 1px solid var(--red);
  background-color: var(--red);
}

/* Panel */

/* Scenes Panel */

.scenes-control-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  width: 100%;
  gap: 10px;
}

.scenes-control-left,
.scenes-control-right {
  display: flex;
  align-items: center;
}

.scenes-control-center {

}

.scenes-control-btn {
  padding: 5px 7px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.scenes-control-btn:hover,
.scenes-control-btn:focus {
  opacity: .5;
}

.scenes-control {
  text-align: center;
  color: #fff;
  background-color: #353A68;
  border: 1px solid #353A68;
  border-radius: 4px;
  height: 22px;
  font-size: 12px;
  width: 100%;
}

.scenes-control:disabled {
  color: #fff;
  background-color: #353A68;
  border: 1px solid #353A68;
}

.scene-layout-preview {
  position: relative;
  width: 205px;
  height: 0;
  padding-top: 56.52%;
  background-color: var(--SecondaryTextColor);
}

.scene-layout-preview>img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.scene-placement-number {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.scene-placement-number-text {
  color: var(--DefaultTextColor);
  background: var(--red);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5px;
  font-size: 14px;
  line-height: 12px;
}

.scene-list-more-action {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.action-more-dropdown .action-more-dropdown-components::after {
  content: none;
}

.action-more-dropdown .dropdown-menu {
  border-radius: 12px;
  padding: 5px 0;
}

.action-more-dropdown .dropdown-menu .dropdown-item {
  color: #858585;
  font-size: 0.875rem;
  border-radius: 12px;
  padding: 0.3125rem 0.9375rem;
}

.action-more-dropdown .dropdown-menu .dropdown-item:focus {
  color: #16181b;
  background-color: #e9ecef
}

.io-btn-view-output {
  display: flex;
  align-items: center;
  text-transform: inherit;
  font-size: 14px;
  line-height: 14px;
}

.io-btn-view-output-icon {
  margin-left: 15px;
}

.scene-object-position {
  display: flex;
  align-items: center;
}

.scene-object-position-item {
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
}
.scene-object-position-item:last-child {
  margin-right: 0;
}

.btn-scene-object {
  padding: 0;
}

.layer-properties {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.layer-selection-wrapper {
  margin-right: 10px;
}

.layer-selection {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.layer-selection-input {
  text-align: center;
  border-radius: 4px;
  color: #858585;
  background-color: #353A68;
  border: 1px solid #353A68;
  min-width: 54px;
  height: 30px;
}

.layer-selection-input:focus,
.layer-selection-input:hover {
  color: #858585;
  background-color: #353A68;
  border: 1px solid #353A68;
}

.btn-layer-select {
  border-radius: 6px;
}

/* Scenes Panel */

/* Output Panel */
.output-panel-subpanel {
  flex: 1 1 auto; 
  height: 100%;
  width: 70%;
}

.btn-social-share {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;  
}

.aspect-ratio-render {
  width: 100%;
  height: 100%;
}
.preview-render {
  width: 100%;
  height: 100%;
}

.btn-headphone {
  padding: 0 4px;
}

.audio-mix-subpanel {
  width: 100%;
  flex-grow: 1;
  height: 40%;
  border-left: 1px solid rgb(40, 45, 56);
}

.audio-mix-channel {
  padding: 1.25rem;
  overflow: auto;
  max-height: calc(var(--height) - 46px);
}

.audio-mix-channel-row {
  display: flex;
  align-items: center;
  margin-bottom: 1px;
}
.audio-mix-channel-row:last-child {
  margin-bottom: 0;
}

.audio-mix-channel-text {
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.07px;
  color: var(--DefaultTextColor);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 105px;
  max-width: 100%;
  margin-right: 5px;
}

.audio-mix-channel-slider.rc-slider {
  /* margin: 0 15px 0 5px; */
}

.audio-mix-channel-slider.rc-slider .rc-slider-rail {
  background-color: #353A68;
}
.audio-mix-channel-slider.rc-slider .rc-slider-track {
  background-color: #8286A9;
}

.audio-mix-channel-slider.rc-slider .rc-slider-step {}

.audio-mix-channel-slider.rc-slider .rc-slider-handle {
  background-color: #01D1FA;
  border: solid 2px #fff;
  opacity: 1;
}

.audio-mix-channel-action {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.audio-mix-channel-action .btn-volume {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  width: 26px;
  min-width: 26px;
  height: 26px;
}

/* Output Panel */

/* Live Sources */
.main-content {
  display: flex;
  width: var(--width);
  height: var(--height);
}

.input-monitor-panel {
  width: 70%;
  flex-grow: 1;
  height: var(--height);
}

.input-streams-panel {
  min-width: 247px;
  width: 30%;
  flex-grow: 1;
  border-left: 1px solid rgb(40, 45, 56);
}

.input-streams-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: calc(var(--height) - 46px);
}

.input-streams-list {
  width: 100%;
  height: calc(var(--height) - 46px);
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.input-streams-list-header {
  color: white;
  font-size: smaller;
  font-weight: bold;
  padding: 5px 5px 5px 5px;
  border-bottom: 1px solid #434343;
  background: rgba(0,0,0,0.2);
}

.input-streams-list-item {
  padding: 5px 5px 5px 10px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-left: 1px solid #434343;
  border-bottom: 1px solid #434343;
  background-color: rgba(0,0,0,0.1);
}

.input-streams-list-item:hover,
.input-streams-list-item.active {
  background-color: #22293B;
}

.input-streams-list-inner {
  width: 100%;
}

.input-streams-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.06px;
  color: #FFFFFF;
  display: inline-block;
  margin-left: 10px;
  flex-grow: 1;
}

.input-streams-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.input-streams-action .btn {
  margin-left: 10px;
}

.input-streams-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
}

.input-streams-bottom .io-btn-add-input-streams {
  width: 100%;
  color: #fff;
  background-color: #353A68;
  border-radius: 0;
  padding: 8px 12px;
}

/* Live Sources */

/* Tag Selector */
.tag-selector-panel {
  width: 30%;
  height: 100%;
  border-left: 1px solid #282D38;
  min-width: 300px
}

.tag-selector {
  overflow-y: auto;
  max-height: calc(var(--height) - 46px);
}

.panel-subheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #182031;
  padding: 10px 20px;
  width: 100%;
}

.panel-subheader-content {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.07px;
  color: var(--DefaultTextColor);
}

.tag-saved-selections {
  list-style: none;
  padding-left: 0.9375rem;
  margin: 0;
}

.tag-saved-selections>li {
  padding: 10px 5px;
  display: inline-block;
}

.treeview {
  list-style: none;
  padding: 5px;
  margin: 0;
  gap: 4px;
  display: flex;
  flex-wrap: wrap;
}

.treeview-branch {
  position: relative;
  cursor: pointer;
  padding: 1px 4px;
  border: 1px solid #495158;
  background: #495158;
  border-radius: 10px;
}
.treeview-branch:hover {
  background-color: var(--TreviewBranchColor);
}

.treeview-branch-title {
  font-size: 13px;
  color: var(--DefaultTextColor);
}

.treeview-branch-parent-title {
  font-size: 13px;
  color: var(--DefaultTextColor);
  padding: 3px 6px;
  text-transform: uppercase;
  font-weight: bold;
}

.treeview-branch-badge {
  font-size: 13px;
  color: #B7B7B7;
  background-color: #343a40;
  border: 1px solid #343a40;
  border-radius: 100px;
  padding: 3px 10px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
}
.treeview-branch-badge:hover {
  color: var(--DefaultTextColor);
  background-color: #31354b;
  border: 1px solid #31354b;
}

.btn-treeview-remove {
  font-size: 5px;
  padding: 3px 8px;
}
.btn-treeview {
  font-size: 12px;
  color: #B7B7B7;
  border-radius: 100px;
  padding: 5px 10px;
}

.treeview-branch-sub {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.treeview-branch-sub>li {
  position: relative;
  padding: 5px;
  display: inline-block;
}

/* .treeview-branch-sub>li:last-child {
  padding: 8px 0 8px;
} */
 
/* .treeview-branch-sub>li::before {
  content: "";
  width: 38px;
  height: 1px;
  background-color: #707070;
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: -43px;
} */

/* Tag Selector */

/* Media Library */
.media-library-panel {
  height: var(--height);
  width: 70%;
  flex-grow: 1;
}

.media-library-wrapper {
  height: calc((100%) - 50px);
  max-height: calc((100%) - 50px);
  overflow: auto;
  margin-top: 5px;
  margin-bottom: 5px;
}

.media-library-wrapper::-webkit-scrollbar-track {
  margin-top: -5px;
  margin-bottom: -5px;
}

.io-media-wrap {
  width: var(--width);
  height: var(--height);
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
}

.io-media-thumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.media-overlay-action {
  position: absolute;
  top: 0;
  right: 0;
}

.io-dropzone {
  border: 1px dashed #8286A9 !important;
  border-radius: 23px !important;
  width: 100% !important;
  height: auto !important;
  border: 0 !important;
}
.io-dropzone input {
  top: 0 !important;
}

.media-upload-grid {
  display: flex;
  flex-wrap: wrap;
  width: var(--width);
  max-height: 300px;
  overflow: auto;
  margin: 15px 0 10px;
}
.media-upload-grid-item {
  width: calc(100% / 4 - 10px);
  margin: 5px;
}
.media-upload-grid-item-layer {
  position: relative;
  padding-top: 56.52%;
  background: #000;
}
.media-uploaded-thumb {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.media-upload-grid-item-layer .uploaderMediaOverAction {
  opacity: 0;
  transition: all 0.2s ease;
}
.media-upload-grid-item-layer:hover .uploaderMediaOverAction {
  opacity: 1;
}

.uploaderMediaOverAction {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-uploaderMediaRemove {
  color: var(--SecondaryTextColor);
  background-color: var(--DefaultTextColor);
  padding: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-uploaderMediaRemove:hover,
.btn-uploaderMediaRemove:focus {
  color: var(--SecondaryTextColor);
}

.btn-uploaderMediaRemove svg {
  width: 6px;
  height: auto;
}

/* Media Library */

.window-spinner {
  width: var(--width);
  height: var(--vh);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.window-spinner-text {
  color: var(--DefaultTextColor);
  font-size: clamp(0.875rem, 0.729vw, 14px);
  margin-top: clamp(0.625rem, 0.729vw, 0.625rem);
  text-align: center;
}

/* window */
.lm_goldenlayout {
  background: transparent;
    height: calc(100vh - 110px);
    width: calc(50%) !important;
    margin: 0px 10px;
    margin-top: 10px;
}

.lm_content {
  background: #1E232E;
  border: 1px solid #31354b;
}

.lm_header {
  height: 30px !important; 
  display: flex;
  align-items: center;
  background-color: #3b3f5c !important;
  border-radius: 0px;
  padding-left: 3px;
  padding-top: 4px;
}

.lm_header .lm_tab {
  height: 27px !important;
  display: flex;
  align-items: center;
  font-family: var(--font);
  font-size: 0.9rem;
  font-weight: 800;
  box-shadow: none !important;
  background: #27293a;
  height: 100%;
  padding: 0 0.5rem;
  margin-right: 5px;
  padding-right: 30px;
}

.lm_header .lm_tab.lm_active{
  height: 27px !important;
  display: flex;
  align-items: center;
  font-family: var(--font);
  font-size: 0.9rem;
  font-weight: 800;
  box-shadow: none !important;
  background: #31354b;
}

.lm_header .lm_controls {
  right: 1.125rem;
}

.lm_splitter.lm_vertical {

}

/* window */

/* Scene Panel */

.scene-preview {
  width: calc(var(--width) - 220px);
}


.scene-object-list {
  padding: 0px;
  padding-top: 0px;
  overflow-y: auto;
  border-radius: 0px;
  max-height: calc(100%);
  height: calc(100% - 40px);
}

.scene-object-list-item {
  background-color: transparent;
  color: white;
  border-radius: 0px !important;
  border: 0px;
  border-bottom: 1px solid #434343;
  border-left: 1px solid #434343;
  padding: 8px 10px;
  display: flex;
  align-items: center;
}

.scene-object-list-item-selected {
  background-color: #ba0a0afe;
}

.scene-object-list-inner {}

.scene-object-title {}

.scene-object-action {}

.scene-object-item-inner {
  display: flex;
  align-items: left;
  justify-content: left;
  width: 100%;
  grid-gap: 10px;
}


.scene-object-title {
  line-height: 15px;
  font-weight: 400;
  letter-spacing: 0.06px;
  padding-right: 5px;
  color: var(--DefaultTextColor);
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-scene-object-remove {
  font-size: 10px;
  line-height: 8px;
  letter-spacing: 0.04px;
  color: var(--DefaultTextColor);
  background: #353A68;
  border: 1px solid #353A68;
  border-radius: 100px;
  text-transform: uppercase;

}

.scene-object-properties {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 46px);
  overflow: auto;
  overflow-x: hidden;
  padding: 0px 0px;
}


.scene-object-properties-row {
  display: flex;
  flex-wrap: nowrap;
  min-width: 340px;
  padding: 0px 0px 0px 0px;
  border-bottom: 1px solid var(--BorderColor);
}

.scene-object-properties-col {
  min-width: 75px;
  display: flex;
  align-items: left;
  width: auto;
}

.scene-object-properties-col:last-child {
  width: 100%;
}

.scene-object-properties-left-wrapper {
   width: auto;
   display: flex;
}

.scene-object-properties-right-wrapper {
  padding: 5px 10px 5px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
}

.scene-object-properties-title {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.06px;
  color: #858585;
  align-self: center;
  padding-left: 10px;
  padding-right: 10px;
  width: 120px;
}

.scene-object-properties-input {
  color: var(--DefaultTextColor);
  background-color: #22283B;
  border: 1px solid #22283B;
  border-radius: 0;
  width: 100%;
}

.scene-object-properties-input:focus {
  color: var(--DefaultTextColor);
  background-color: #22283B;
  border: 1px solid #22283B;
}


/* Scene Panel */

.blank-panel-window {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: var(--width);
  height: calc(var(--height) - 2.875rem);
  padding: 1.25rem;
}
.blank-panel-window .blank-panel-window-text {
  color: var(--DefaultTextColor);
  font-size: clamp(0.875rem, 0.875rem, 0.729vw);
}

.lm_content {
  max-height: 100%;
  height: 100%
}

.span.grippy {
  content: '....';
  width: 10px;
  height: 20px;
  display: inline-block;
  overflow: hidden;
  line-height: 5px;
  padding: 3px 4px;
  cursor: move;
  vertical-align: middle;
  margin-top: -.7em;
  margin-right: .3em;
  font-size: 12px;
  font-family: sans-serif;
  letter-spacing: 2px;
  color: #cccccc;
  text-shadow: 1px 0 1px black;
}
span.grippy::after {
  content: '.. .. .. ..';
}

.pulse {
  opacity: 1;
  animation: fade 2s infinite;
}


@keyframes fade {
0%,100% { opacity: 0 }
50% { opacity: 1 }
}

.flexlayout__tabset-selected {
  background-image: none !important;
}

.flexlayout__tab {
  overflow: hidden !important;
}
.flexlayout__layout {
  position: relative !important;
  width: 100%  !important; 
  height: 100%  !important;
    --color-base: #3b3f5c !important;
    --color-background: #202230 !important;
    --color-tabset-background: #3b3f5c !important;
    --color-tab-selected-background: #31354b !important;
    --color-text: #eeeeee;
    --color-base: black;
    --color-1: #3b3f5c !important;
    --color-2: #1a1a1a;
    --color-3: #262626;
    --color-4: #3b3f5c;
    --color-5: #404040;
    --color-6: #4d4d4d;
    --color-drag1: rgb(207, 232, 255);
    --color-drag2: rgb(183, 209, 181);
    --color-drag1-background: rgba(128, 128, 128, 0.15);
    --color-drag2-background: rgba(128, 128, 128, 0.15);
    --font-family: var(--font) !important;
    --font-size: small !important;;
    font-weight: 600 !important;
    --color-overflow: gray;
    --color-icon: gray;
    --color-tabset-background: #3b3f5c !important;
    --color-tabset-background-selected: var(--color-1) !important;
    --color-tabset-background-maximized: var(--color-1) !important;
    --color-tabset-divider-line: #31354b;
    --color-tabset-header-background: var(--color-1) !important;
    --color-tabset-header: var(--color-text);
    --color-border-background: var(--color-1);
    --color-border-divider-line: var(--color-4);
    --color-tab-selected: var(--color-text);
    --color-tab-selected-background: var(--color-4);
    --color-tab-unselected: gray;
    --color-tab-unselected-background: #27293a !important;
    --color-tab-textbox: var(--color-text);
    --color-tab-textbox-background: var(--color-3);
    --color-border-tab-selected: var(--color-text);
    --color-border-tab-selected-background: var(--color-4);
    --color-border-tab-unselected: gray;
    --color-border-tab-unselected-background: var(--color-2);
    --color-splitter: rgba(0,0,0,0) !important;
    --color-splitter-hover: var(--color-4);
    --color-splitter-drag: var(--color-5);
    --color-drag-rect-border: var(--color-4);
    --color-drag-rect-background: var(--color-1);
    --color-drag-rect: var(--color-text);
    --color-popup-border: var(--color-6);
    --color-popup-unselected: var(--color-text);
    --color-popup-unselected-background: black;
    --color-popup-selected: var(--color-text);
    --color-popup-selected-background: var(--color-4);
    --color-edge-marker: gray;
}