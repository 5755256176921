.sceneNode {
    background-color: rgb(75, 75, 75);
    border-color: rgb(124, 10, 139);
    border-radius: 0px;
    border-width: 2px;
    width: 160px;
    padding: 0px;
    color:white
}

.sceneNode .label {
    padding: 0px;
    text-overflow: ellipsis;
    max-height: 1.5em;
    height: 1.5em;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 5px;
}

.sceneNode .preview {
    width: 100%;
}


.node {
    width: 100%;
    margin-top: 10px;
    height: auto;
    border: 1px solid rgb(107, 107, 107);
    background: rgb(51, 51, 51);
    color: rgb(255, 255, 255);
}

.nodeStyle {
    border: 1px solid rgb(107, 107, 107);
    background: rgb(51, 51, 51);
    color: rgb(255, 255, 255);
}

.react-flow__node {
    border: 1px solid rgb(107, 107, 107);
    background: rgb(51, 51, 51) !important;
    color: rgb(255, 255, 255) !important;
}