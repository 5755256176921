.player {
    width: 100%;
    height: 100%;
  }
  
  .player-red{
    background-color: red;
  }
  
  .player-transparent {
    background-color: transparent;
  }
  
  .player-border-red {
    outline: 1px solid red;
  }
  
  .player-border-green {
    outline: 1px solid green;
  }
  
  .player-border-none {
    outline: 0;
  }
  
  .player-border-width-0 {
    outline-width: 0;
  }
  
  .player-border-width-1 {
    outline-width: 1px;
  }
  
  .player-video {
    width: 100%;
    height: 100%;
  }
  
  .player-video-visible {
    visibility: visible;
  }
  
  .player-video-hidden {
    visibility: hidden;
  }
  
  .player-video-red {
    background-color: red;
  }
  
  .player-video-transparent {
    background-color: transparent;
  }
  
  .player-video-title {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    max-width: 100%;
    padding: 1px;
    text-overflow: ellipsis;
  }
  