/* Size */
.full-height {
  height: 100% !important;
}

.full-width {
  width: 100% !important;
}

/* Navbar */
.nav-title {
  color: white;
  font-size: 2vh;
  margin-top: 5px;
  font-size: 2em;
}

.navbar {
  padding-top: 1.5rem;
  padding-bottom: 0;
  height: 60px;
}

.navbar-button {
  margin-right: 10px;
}

.navbar-dropdown-image {
  height: 3rem;
  margin-left: 10px;
}

/* Input Monitor */
.input-monitor-layer {
  position: relative;
  width: 100%;
  height: 100%;
  background: #000;
}

.input-monitor-layer-addPIPSource {
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
}

#button-tooltip {
  pointer-events: none;
}

.input-monitor-overlay-title {
  font-size: 14px;
  letter-spacing: 0.08px;
  color: var(--DefaultTextColor);
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0;
  margin-right: 15px;
  z-index: 2;
}

.toolbar {
  padding: 0px;
  position: absolute;
  bottom: 10px;
  right: -5px;
}

.link-share-dialog>p {
  color: black;
}

.link-share-dialog>input {
  color: black;
  width: 100%;
}

.navNormal {
  height: 2.2rem;
  margin-left: 1rem;
  padding: 16px;
  font-family: inherit;
  line-height: 1;
  letter-spacing: 0.06px;
  text-align: left;
  color: #99999f;
}

.nav-link-text {
  color: white !important;
  text-overflow: ellipsis !important;
}

/* Media Items */
.media-item-layer {
  width: 100%;
}

.media-item-addPIPSource {
  width: 160px;
  height: 160px;
  background: #eee;
  background-size: 30px 30px;
}

.background_tp {
  display: inline-block
}

.media-preview-grid-b {
  overflow-y: auto;
  width: 70%;
  max-height: 90%;
  height: 90%;
  margin-right: 10px;
  margin-top: 20px;
  display: inline-block;
}

/* Media Object Preview */
.signed-image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
  margin: 0px;
}

.signed-video {
  width: 100%;
  height: 100%;
}

.media-object-preview-container {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
}

.media-object-preview-image {
  display: inline-block;
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
  margin-top: auto;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.media-object-preview-icon {
  display: inline-block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%
}

/* Preview */
.preview-video {
  display: inline-block;
  z-index: 2;
  object-fit: fill;
}

.preview-video-type-webrtc {
  width: 100%;
  height: 100%;
  background: green;
  display: grid;
  justify-items: center;
  align-items: center;
}

.preview-video-type-video-container {
  height: 100%;
}

.preview-video-type-video {
  display: inline-block;
  z-index: 2;
  object-fit: fill;
}

.preview-video-type-video-thumbnail {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.preview-layer-position {
  top: 5px;
  right: 15px;
  font-size: 3em;
}

.source-list-item {
  margin: 0px;
  padding: 0;
  background-color: transparent;
}

.source-list-item-green {
  border: 1px solid green;
}

.source-list-item-black {
  border: 1px solid #252B39;
}

@keyframes blinkingText {
  0% {
    color: #ff5e00;
  }

  50% {
    color: #ffffff;
  }

  100% {
    color: #ff5e00;
  }
}

p {
  color: white;
}

.rootDiv {
  width: 100vw;
  height: calc(100vh - 20px);
  padding: 0px;
  overflow: hidden;
}

.scene-name-input {
  padding-top: 2px;
  padding-bottom: 2px;
  height: 22px;
  background: #6b6b6b;
  font-size: smaller;
}

.scene-name-input-button {
  padding-top: 2px;
  padding-bottom: 2px;
}

.dnd-list {
  min-width: 220px;
  width: 220px;
  height: 100%;
  border-right: 1px solid #282D38;
}

.dnd-list ul {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100% - 87px);
  padding: 0px ;
  margin: 0px;
}

.dnd-list ul>li {
  justify-content: center;
  margin-bottom: 5px;
}

.dnd-list div[data-baseweb="dnd-list"] {
  max-height: 100%;
  height: 100%;
}


.sceneListControl {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centerVANDH {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.centerVANDH2 {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translateX(-50%, -50%);
}

.outer-node-container {
  display: inline-block;
  vertical-align: top;
  color: white;
  background-color: #2c303a;
  width: 160px;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.uploaderInside {
  min-height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border: 1px dashed #8286A9;
  border-radius: 23px;
  padding: 25px 10px;
}

.uploaderInsideIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}
 
.uploaderInsideText {
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.06px;
  color: #B7B7B7;
}

.media-item-swap-hover {
  position: relative;
  padding-top: 100%;
  background: #000;
}
.media-item-swap-hover:hover {
  outline: 2px solid rgb(6, 208, 223) !important;
}

.media-items-grid {
    margin: 0;
    list-style: none;
    padding: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100%;
    display: grid;
    list-style-type: none;
    row-gap: 5px;
    column-gap: 5px;
}

.media-items-grid-item {
  width: 100%;
  aspect-ratio: 1 / 1;
  flex-grow: 1;
  display: inline-block;
  vertical-align: top;
  color: white;
  --checkerSize: 1px;
  --checkerColor: rgb(150, 150, 150);
  --checkerAltColor: white;
  background-image: linear-gradient(45deg, magenta 25%, transparent 25%), linear-gradient(135deg, orange 25%, transparent 25%), linear-gradient(45deg, transparent 75%, dodgerblue 75%), linear-gradient(135deg, transparent 75%, darkorchid 75%);
  background-image: linear-gradient(45deg, var(--checkerColor, magenta) 25%, transparent 25%), linear-gradient(135deg, var(--checkerColor, orange) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, var(--checkerColor, dodgerblue) 75%), linear-gradient(135deg, transparent 75%, var(--checkerColor, darkorchid) 75%);
  background-color: var(--checkerAltColor);
  background-size: calc(2 * var(--checkerSize)) calc(2 * var(--checkerSize));
  background-position: 0 0, var(--checkerSize) 0, var(--checkerSize) calc(-1 * var(--checkerSize)), 0px var(--checkerSize);
}

.live-source-grid {
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  height: 100%;
  overflow-y: auto;
  width: calc(100% - 10px);
  max-height: calc(100% - 6em);
  height: 90%;
  margin: 10px 10px 0 0;
  padding: 0;
}

.outer-node-container-2 {
  vertical-align: top;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  flex: 1 1 10vw;
  margin: 10px;
  width: 0;
  height: fit-content;
  max-width: 50%;
  min-width: 300px;
  /*width: 20%;
  min-width: 300px;
  height: auto;
  margin-top: 0px;
  margin-left: 2.5%;
  margin-right: 0px;
  margin-bottom: 15px;*/
}

.toolbar-le {
  width: calc(100%-40px);
  margin-bottom: 0px;
  padding: 8px;
  padding-top: 10px;
  border-bottom: 1px solid #777777;
  background-color: #2b2f46;
}

.low-pad .tr .td {
  padding: 6px;
  border-right: 0px;
  text-align: center;
  vertical-align: middle;
}


.table td {
  vertical-align: middle;
  text-align: left;
}

.preview-container-sources {
  position: relative;
  background: #000000;
  height: 100%;
  width: 100%;
  max-width: 100%;
}

.list-group-item:hover,
.list-group-item.active {
  z-index: 2;
  color: var(--DefaultTextColor);
  cursor: pointer;
  background-color: #242B3D;
  border-color: #242B3D;
}

.subpanel-header {
  margin-bottom: 0px;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  color: var(--DefaultTextColor);
  display: inline-block;
}

.preview-container {
  width: var(--width);
  height: calc(var(--height) - 40%);
}

.output-preview-container {
  width: var(--width);
  height: calc(var(--height) - 40px);
  padding: var(--panelPadding); 
}

.preview-container-inner {
  width: 100%;
  height: calc(var(--height) - 40px);
  max-width: 100%;
  max-height: 100%;
  overflow: visible;
  padding: var(--panelPadding);
}

.transition-control-container {
  margin: 10px;
  margin-bottom: 20px;
  font-size: 1vh;
}

.transition-control-container>b {
  color: white;
}

.transition-control-container-button {
  display: inline-block;
  padding-top: 10px;
}

.transition-control-container-program-out {
  padding-top: 10px;
  margin-left: 10px;
}

.transition-autoplay-on-label {
  color: white;
  display: inline-block;
  margin-left: 20px;
  animation: blinkingText 2s infinite;
}

.transition-autoplay-off-label {
  color: white;
  display: inline-block;
  margin-left: 20px;
}

.source-toolbar {
  display: inline-block;
  float: right;
}
.source-toolbar svg {
  margin-left: 10px;
  cursor: pointer;
}

.source-header {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  height: 35px;
  top: 2px;
  width: calc(100% - 14px);
  left: 3px;
  margin-left: 3px;
  z-index: 1
}

.rect {
  max-height: 100%;
}

.content-cnt-cont {
  height: 100%;
  overflow: hidden;
}

.enblacken * {
  color: black;
}

.helloUser {
  font-size: 1.2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #898989;
}

.textDefault {
  color: white;
}

.sourceContainer {
  height: 100%;
}

#frame #remoteVideo {
  object-fit: fill;
}

.sourceContainer:hover {
  outline: 2px dashed #17b6f5;
  outline-offset: -1px;
}

.sourceContainer:hover>.sourceContainerComponent {
  visibility: visible;
}

.sourceContainer:hover>.render-target-title-inner  {
  visibility: visible;
}

.sourceContainer .render-target-title-inner {
  visibility: hidden;
}


.flash {
  filter: brightness(1.5) saturate(1.5);
}

.sourceContainer:hover .renderTargetRemoveButton {
  visibility: visible;
}

.sourceContainerComponent {
  visibility: hidden;
  background-color: rgba(70, 70, 70, 0.6);
  width: fit-content;
  padding: 10px;
}

.renderTargetToolbar {
  position: absolute;
  pointer-events: all;
  z-index: 1001;
  right: 0px;
  top: 0px;
  background-color: #1cabe4; ;
  border-radius: 0px;
  color: darkred;
  font-size: small;
  padding: 5px;
}

.renderTargetRemoveButton {
  z-index: 100;
  margin-right: 0px;
  color: darkred;
}

.renderTargetRemoveButton :hover {
  color: red;
  cursor: default;
}

.previewHeader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.previewHeader:hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
}

.previewHeader .previewOnHover {
  visibility: hidden;
}

.previewHeader:hover .previewOnHover {
  visibility: visible;
}

.previewOnHover {
  position: absolute;
  top: 5px;
  right: 5px;
  color: red;
  z-index: 3;
  width: auto;
}

.previewHeader>span {
  background: red;
  padding-left: 5px;
  padding-right: 8px;
  padding-top: 1px;
  padding-bottom: 1px;
  text-align: center;
  color: white;
}

/* .preview-content-cnt {
  height: 100%;
  overflow: hidden;
} */

.layoutPreview {
  background: black;
  border: 1px solid black;
  height: 90px;
  width: 160px;
}

.layoutPreview>img {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 0;
  width: 100%;
  height: 100%;
}

.preview-layout-container {
  height: 50px;
  justify-content: space-between;
  display: flex;
  align-items: flex-start;
  overflow-x: auto;
}

.preview-layout-options {
  flex: 1;
}

.preview-layout-sequence-container {
  flex: 4;
  display: flex;
  font-size: 1vh;
}

.preview-layout-sequence-container>b:first-of-type {
  color: white;
  margin-top: 5px;
}

.preview-layout-sequence-container>b:last-of-type {
  color: white;
  margin-left: 10px;
  margin-top: 5px;
}

.preview-layout-sequence-container>p {
  margin: 0;
  background: red;
  margin-left: 20px;
  padding-left: 10px;
  padding-right: 14px;
  padding-top: 5px;
  padding-bottom: 1px;
  text-align: center;
  color: white;
}

.preview-layout-sequence-button {
  margin-right: 0px;
  margin-top: -2px;
  width: auto;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: smaller;
}

.preview-canvas {
  background: black;
  height: 90px;
  width: 160px;
  display: none;
}

/* Three Render */
.three-render-video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  /* visibility: none; */
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  height: 100%;
}

#render-target {
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  height: 100%;
}

/* TagEditorPanel */
.tag-editor-panel-search-button {
  margin-right: 0;
}

.tag-editor-panel-remove-button {
  margin-left: 10px;
}

.tag-editor-panel-edit-mode-button {
  float: right;
  width: 100%;
}

.tag-editor-panel-container {
  padding: 10px;
}

.tag-editor-panel-container>hr {
  background: #aaa;
}

.tag-editor-panel-container-input-group {
  width: 100%;
}

.tag-editor-panel-container-form-control {
  height: 40px;
}

.tag-editor-panel-container-button {
  display: inline-block;
  border-radius: 0px 4px 4px 0px;
}

.tag-editor-panel-container-sortable-tree {
  margin-top: 20px;
  margin-left: 20px;
  overflow: auto;
}

/* Aspect Ratio */
.aspect-container {
  width: 100%;
  height: 100%;
}

.layoutPreview_active {
  border: white;
}

.userActiveTeam {
  color: white;
}

.userName {
  color: white;
}

.faHoverIcon:hover {
  color: lightblue
}

.subpanel {
  background: #31354b 0% 0% no-repeat padding-box;
}

.space-before {
  padding-left: 5px;
}

.table td {
  padding: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-right: 0px;
}

.subpanel-dark {
  background: #31354b 0% 0% no-repeat padding-box;
}

.form-label-smaller {
  color: white;
  font-size: smaller;
  vertical-align: middle;
  width: 55px;
  padding-left: 5px;
}


.subpanel-right {
  border-left: 1px solid #282D38;
  padding: 12px;
  width: calc(30% - 10px);
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  display: inline-block;
  vertical-align: top;
}

.scene-objects-panel {
  display: inline-block;
  vertical-align: top;
  max-width: 35%;
  width: 35%;
  height: calc(var(--height) - 40%);
}

.subpanel-scene-objects {
  display: inline-block;
  vertical-align: top;
  height: 100%;
  max-height: 40%;
  width: 35%;
  max-width: 35%;
  border-right: 1px solid #282D38
}

.subpanel-scene-object-props {
  display: inline-block;
  width: 65%;
  height: 40%;
}

.subpanel-tags {
  margin-bottom: 0.5em;
}

.subpanel-available-tags {
  margin-top: 15px;
}

.live-resources-wrapper {
  height: calc(100% - 40px);
  width: var(--width);
  max-height: calc(100% - 40px);
  padding: 5px;
  padding-bottom: 0px;
}

.grid-container {
  height: calc(100% - 25px);
  max-height: calc(100% -25px);
  padding: 10px;
  width: var(--width);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  gap: 5px;
}

.grid-box {
  width: 100%;
  height: 100%;
  padding-right: 5px;
  padding-bottom: 5px;
}

.input-monitor-layer-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
}

.centerVideoIcon {
  display: inline-block;
  position: relative;
  top: -50%;
  right: -50%;
  transform: translate(-50%, -50%);
}

.modal-background {
  background: #3a3a3d;
  color: #ffffff;
}

.headerText {
  margin: 10px 0 0 10px;
  font-size: 1.2rem;
}


.form-control1 {
  padding: 1px;
  font-size: smaller;
  font-weight: 400;
  color: #fbffff;
  background-color: #525252;
  background-clip: padding-box;
  border: 0px solid #343a40;
  border-radius: 1em;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  width: fit-content;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-input-placeholder {
  color: #fbffff;
}

.source-list-item-green {
  border: 0px solid #17b6f5;
  outline-offset: -1px;
}

.source-list-item-black {
  border: 0px solid #252B39;
  outline-offset: -1px;

}

.svg-inline--fa {
  cursor: pointer;
}

.sidebar-nav {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100px;
  display: block;
  background: var(--sidebar-background-color);
}

.sidebar-nav-button {
  margin: 0px;
  padding-top: 20px;
  padding-bottom: 15px;
}

.hover-highlight:hover {
  background-color: #132e42 !important;
}

.select-heighlight {
  background-color: #19b4f2 !important;
}

.render-target-title-inner {
  z-index: 1001;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: rgba(70, 70, 70, 0.4);
  font-size: 0.8em;
  position: absolute;
  bottom: 0px;
}

.live-source-preview-text {
  display: inline-block;
  margin-left: 10px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.media-query-preview-container {
  background: #000000;
  width: 100%;
  height: 100%;
}

.media-query-preview-container div{
  width: 100%;
  height: 100%;
}

.media-query-preview-container .render-target-title-inner  {
  visibility: hidden;
}

.media-query-preview-container:hover>.render-target-title-inner {
  visibility: visible;
}

.render-target-title-selected {
  visibility: visible;
}


.square {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}

.square .content {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.checked-background {
  --checkerColor: #969696;
  --checkerAltColor: #fff;
  --checkerSize: 4px;
  background-size: calc(2*var(--checkerSize)) calc(2*var(--checkerSize));
  background-position: 0 0,var(--checkerSize) 0,var(--checkerSize) calc(-1*var(--checkerSize)),0 var(--checkerSize);
  background-color: var(--checkerAltColor);
  background-image: 
    linear-gradient(45deg,var(--checkerColor,#f0f) 25%,transparent 0),
    linear-gradient(135deg,var(--checkerColor,orange) 25%,transparent 0),
    linear-gradient(45deg,transparent 75%,var(--checkerColor,#1e90ff) 0),
    linear-gradient(135deg,transparent 75%,var(--checkerColor,#9932cc) 0);
}

.fullSize {
  width: 100%;
  height: 100%;
  place-content: baseline !important; /*place in top instead of center */
}

.react-slideshow-fadezoom-images-wrap > div {
  display: flex !important;
}